<template>
  <nav class="navbar flex items-center justify-between bg-main-dark">
    <nuxt-link class="m-3" to="/">
      <h1 class="align-middle text-2xl font-medium text-white">
        Aventuria Database
      </h1>
    </nuxt-link>
    <div class="w-80 p-1">
      <input
        v-model.lazy="searchTerm"
        ref="searchInput"
        type="search"
        :placeholder="$t('search')"
        class="block w-full border-0 p-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
    </div>
    <div
      class="hamburger m-3"
      :class="hamburgerOpen ? 'hamburger--is-open' : ''"
      @click="hamburgerOpen = !hamburgerOpen"
    >
      <div class="hamburger__item hamburger__item--first" />
      <div class="hamburger__item hamburger__item--middle" />
      <div class="hamburger__item hamburger__item--last" />
    </div>
  </nav>
</template>

<script setup lang="ts">
const { locale } = useI18n();
import { useSearchStore } from "~/stores/search";
import { useEventListener } from "@vueuse/core";
const searchStore = useSearchStore();

const { searchTerm } = toRefs(searchStore);
const searchInput = ref<HTMLInputElement | null>(null);

if (process.client) {
  useEventListener(window, "keydown", (event) => {
    if (event.key === "k" && (event.metaKey || event.ctrlKey)) {
      searchInput.value?.focus();
    }
  });
}

const hamburgerOpen = ref(false);
</script>

<style>
.navbar {
  min-height: 4vh;
}
.hamburger {
  height: 30px;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.hamburger:hover {
  cursor: pointer;
}
.hamburger__item {
  height: 3px;
  width: 100%;
  background: white;
  transition: transform 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95),
    opacity 300ms linear;
}
.hamburger--is-open .hamburger__item--first {
  transform: translate(0, 13px) rotate(45deg);
}
.hamburger--is-open .hamburger__item--middle {
  opacity: 0;
}
.hamburger--is-open .hamburger__item--last {
  transform: translate(0, -14px) rotate(-45deg);
}
</style>
