<template>
  <div class="flex min-h-screen flex-col">
    <CoreAppNavbar />
    <div class="main details">
      <slot />
    </div>
    <CoreAppFooter />
  </div>
</template>
<script setup lang="ts"></script>
<style>
.details {
  min-height: 86vh;
}
</style>
